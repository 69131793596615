import axios from 'axios'
const _ = require('lodash')

export default {
    createChat(tenantId, chatDetails) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Chats/', chatDetails);
    },

    getChat(tenantId, chatId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + chatId)
    },

    getChatThumbProtectedUrl(tenantId, chatId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + chatId + '/Thumb');
    },

    archiveChat(tenantId, chatId, assigningPresenceId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + chatId + '/Archive/' + assigningPresenceId);
    },

    addChatThumb(tenantId, chatId, assigningPresenceId, file) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(
            window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + chatId + '/Thumb/' + assigningPresenceId,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },

    deleteChatThumb(tenantId, chatId, assigningPresenceId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + chatId + '/Thumb/' + assigningPresenceId);
    },

    renameChat(tenantId, chatId, assigningPresenceId, newName) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + chatId + '/Name/' + assigningPresenceId, newName, {
            headers: {
                "content-type": "application/json"
            }
        });
    },

    getMembershipForChat(tenantId, chatId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + chatId + '/Presences')
    },

    getGroupMembersForChat(tenantId, chatId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + chatId + '/Members')
    },

    getGroupMemberCountForChat(tenantId, chatId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + chatId + '/Members/Count')
    },

    getGroupMemberDetailsForChat(tenantId, chatId, presenceId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + chatId + '/Members/' + presenceId)
    },

    getAllGroupMemberDetailsForChat(tenantId, chatId, presenceIds) {
        let requests = [];
        _.each(presenceIds, presenceId => {
            requests.push(
                axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + chatId + '/Members/' + presenceId)
            )
        })
        return axios.all(requests)
    },

    getManagedChatsForOrg(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/Chats');
    },

    deleteMemberFromChat(tenantId, chatId, presenceId, assigningPresenceId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + chatId + '/Members/' + presenceId + '/Presences/' + assigningPresenceId);
    },

    // addMembersToChat(tenantId, chatId, presenceIds, assigningPresenceId) {
    //     let requests = [];
    //     _.each(presenceIds, presenceId => {
    //         requests.push(
    //             axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + chatId + '/Members/' + presenceId + '/Presences/' + assigningPresenceId)
    //         )
    //     });
    //     return axios.all(requests)
    // },

    deleteChat(tenantId, chatId, assigningPresenceId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + chatId + '/' + assigningPresenceId);
    },

    addMembersToChat(tenantId, chatId, presenceIds, assigningPresenceId) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + chatId + '/Members', {
            AssigningPresenceId: assigningPresenceId,
            PresenceIds: presenceIds
        });
    },

    deleteMembersFromChat(tenantId, chatId, presenceIds, assigningPresenceId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Chats/' + chatId + '/Members', {
            data: {
                AssigningPresenceId: assigningPresenceId,
                PresenceIds: presenceIds
            }
        });
    },
}